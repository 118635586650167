.techStack {
    img {
        max-height: 80px;
    }
}

.footerBlock {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 0.25rem;
}
@tabs-bar-margin: 0px;@layout-header-padding: 0 30px;