.notification ul li{
    margin: 3px 0 !important;
}

.body {
    margin-bottom: 10px;
}

.warning {
    color: #ffc069;
}

.info {
    color: #1890ff;
}

.success {
    color: #95de64;
}

.error {
    color: #ff4d4f;
}
@tabs-bar-margin: 0px;@layout-header-padding: 0 30px;