.name {
    margin: 10px 0 0 0;
    font-weight: bold;
}

.role {
    margin: 0;
}

.closeIcon {
    font-size: 1.25em;
}
@tabs-bar-margin: 0px;@layout-header-padding: 0 30px;