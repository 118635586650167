.root {
    background-color: #FFF;

    ul {
        border-bottom: unset;

        li::after {
            bottom: unset !important;
            top: 0;
        }
    }

    box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.name {
    @media screen and (max-width: 768px) {
        display: none;
    }
}

@tabs-bar-margin: 0px;@layout-header-padding: 0 30px;