.vh100 {
    height: 100vh;
}

.w100 {
    width: 100%;
}

.mw100 {
    min-width: 100%;
}

.mh100 {
    min-height: 100vh;
}

.flexWrap {
    flex-wrap: wrap;
}

.br-unset {
    border-right: unset;
}

.mv10 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.mt1-5rem {
    margin-top: 1.5rem;
}

.mb1-5rem {
    margin-bottom: 1.5rem;
}

.p0 {
    padding: 0;
}

.m0 {
    margin: 0;
}

.m1 {
    margin: 1rem;
}

.m2 {
    margin: 2rem;
}

.p1 {
    padding: 1rem;
}

.p2 {
    padding: 2rem;
}

.mw16rem {
    max-width: 16rem;
    width: 100%;
}

.mw20rem {
    max-width: 20rem;
    width: 100%;
}

.mw30rem {
    max-width: 30rem;
    width: 100%;
}

.fs1-25 {
    font-size: 1.25rem;
}

.notes {
    max-width: 35rem;
    color: rgba(0, 0, 0, 0.45);
}

.mt1rem {
    margin-top: 1rem;
}

.mb1rem {
    margin-bottom: 1rem;
}

.mt5px {
    margin-top: 5px;
}

.mb5px {
    margin-bottom: 5px;
}

.mv5px {
    margin-top: 5px;
    margin-bottom: 5px;
}

.h2rem {
    height: 2rem;
}

.whiteBox {
    padding: 1rem;
    display: block;
    background-color: white;
    margin-bottom: 1rem;
}

.noUserSelect {
    user-select: none;
}

.whiteWrapper {
    margin: 1rem 0 1rem 0;

    @media screen and (min-width: 560px) {
        margin: 1rem;
    }

    padding: 1rem;
    background-color: white;
}

.profile-avatar {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon {
        font-size: 32px;
    }
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

.fullsizePopover {
    .ant-popover-inner {
        box-shadow: none;

        .ant-popover-inner-content {
            padding: 0 !important
        }
    }
}

.titleInput {
    width: 32rem;

    @media screen and (max-width: 1240px) {
        width: 26rem;
    }

    @media screen and (max-width: 960px) {
        width: 16rem;
    }

    @media screen and (max-width: 420px) {
        width: 100%;
    }
}

@media (max-width: 480px) {

    .ant-picker-panels, .ant-picker-datetime-panel {
        align-items: center;
        justify-content: center;
        width: 300px !important;
        flex-wrap: wrap !important;
    }

    .ant-picker-date-panel {
        width: 100%;
    }
}

.login-form {
    width: 300px;
    border: solid #f0f0f0;
    border-width: 0px 1px 1px 1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 20px !important;
}

.activation-card {
    margin: 0 1rem;
    
    .ant-card-head {
        background-color: #1890ff;
        color: #fff;
    }
}

.drag {
    cursor: grabbing;
    color: '#999';
}

.whiteSkeleton {
    background-color: white;
    padding: 1rem;
}

.preLine {
    white-space: pre-line;
}

.spaceBetween {
    justify-content: space-between;
}

.defaultSelect {
    min-width: 170px;
    width: 100%;
}

.slim-collapse {
    .ant-collapse-content .ant-collapse-content-box {
        padding: 0px;
    }
    
    .ant-collapse-item .ant-collapse-header {
        padding: 10px 0px;
    }
}

// COLOR CLASSES

.green {
    color: @green-5;
}

.blue {
    color: @blue-5;
}

.b-green {
    border-color: @green-3;
    
    .ant-steps-item-title::after {
        background-color: @green-3 !important;
    }
}

.b-red {
    border-color: @red-3;
}

.bg-red {
    background-color: @red-2 !important;
}

.bg-white {
    background-color: white;
}

// PRINTING
@media print {
    .ant-avatar {
        display: none;
    }

    .blockSingle {
        page-break-inside: avoid;
        display: flex;
        flex-direction: column;
    }

    .darkBorder, .ant-table-container, .ant-table-container td, th, tr, table, thead {
        border-color: @black !important;
    }

    .ant-progress-steps-item {
        border: 1px solid @black !important;
    }

    .notPrintable {
        display: none;
    }

    @page {
        margin: 1.4cm 0.8cm !important;
    }
}

// EDITOR
.blockBody {
    li {
        margin-bottom: 1rem;
    }
}

.editorComment {
    position: relative;
    padding:  0.25rem 0.5rem;
    color: #595959;
    background-color: #f8f8f8;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    &::before,
    &::after {
        content: '';
        height: 1.5rem;
        position: absolute;
        width: 3px;
        background-color: @gold-4;
    }

    &::after {
        right: 0;
        bottom: 0;
    }

    &::before {
        left: 0;
        top: 0;
    }
}

.chronoContainer {
    position: relative;
    border: 1px solid @blue-5;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem 1rem 1rem 1.75rem;

    [contenteditable] {
        outline: 0px solid transparent;
    }

    &::before {
        content: 'СИНХРОН';
        top: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        writing-mode: vertical-lr;
        text-align: center;
        rotate: 180deg;
        background-color: @blue-5;
        color: @white;
    }

    .chronoContentWrapper .chronoBlock {
        position: relative;
        padding-left: 0.5rem;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0; 
            bottom: 0;
            width: 2px;
            background-color: @blue-5;
        }

        .chronoBlockInfo > span {
            padding-right: 0.5rem;
        }
    }
}

.addStoryButton {
    position: absolute;
    top: -16px;
    left: calc(50% - 16px);
}

.popUpHiddenButtons {
    .ant-popover-buttons {
        display: none !important;
    }
}
// EDITOR

.slateLabelInput {
    width: 100%;

    :nth-child(2) {
        flex-grow: 1;
    }
}
